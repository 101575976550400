import './App.css';
import { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import 'react-calendar/dist/Calendar.css';
import { createTheme } from "@mui/material/styles";
import { useInView } from 'react-intersection-observer';
import ScrollWrapper from "./ScrollWrapper";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs'
import React from 'react';
import ReactGA from "react-ga4";
import Month from './components/Month';
import Info from './components/Info';

function App() {
  const { ref, inView, entry } = useInView();
  const now = new Date();
  // nowが10/31のとき11/30になることを確認済み
  const nextMonth = dayjs(now).add(1, 'month').toDate();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1025,
        xl: 1536,
      },
    }
  });
  const isMediaUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [foodData, setFoodData] = useState([{
      "img": "",
      "title": "",
      "price": "",
      "info": "",
      "sub": [
        {
          "title": "",
          "price": "",
        }
      ]
    }
  ]);
  const [foodTakeoutData, setFoodTakeoutData] = useState([{
    "img": "",
    "title": "",
    "price": "",
    "info": "",
    "sub": [
      {
        "title": "",
        "price": "",
      }
    ]
  }
]);
  const [holidayData, setHolidayData] = useState([{
      "year": 2023,
      "month": 4,
      "day": [7,13,14,21,28]
    },{
      "year": 2023,
      "month": 5,
      "day": [5,11,12,19,26]
    }
  ]);
  const [infoData, setInfoData] = useState([{
    "title": "",
    "content": ""
  }
]);
  async function getHolidayData() {
    const res = (await fetch("./holiday_data.json")).json();
    setHolidayData(await res);
  }
  async function getFoodData() {
    const res = (await fetch("./food_data.json")).json();
    setFoodData(await res);
  }
  async function getFoodTakeoutData() {
    const res = (await fetch("./food_takeout_data.json")).json();
    setFoodTakeoutData(await res);
  }
  async function getInfoData() {
    const res = (await fetch("./info_data.json")).json();
    setInfoData(await res);
  }
  React.useEffect(() => {
    getHolidayData();
    getFoodData();
    getFoodTakeoutData();
    getInfoData();
    ReactGA.initialize("G-FKN2C3CLBM");
    ReactGA.send("pageview");
  }, []);

  const nowMonthHolidayData = holidayData.filter(h => h.year === now.getFullYear() && h.month === now.getMonth() + 1).flatMap(h => h.day);
  // filterの後半：nowが12月で年をまたぐとき（now.getMonthは1~12月に0~11で数字が入るので注意）
  const nextMonthHolidayData = holidayData.filter(h => (h.year === now.getFullYear() && h.month === nextMonth.getMonth() + 1) || (now.getMonth() == 11 && h.year === now.getFullYear() + 1 && h.month === 1)).flatMap(h => h.day);
  return (
    <div className="App">
      <ScrollWrapper inView={inView} theme={theme}>
        <div ref={ref}>
          {/* PC用ヘッダー */}
          <Toolbar sx={{ 
                  flexWrap: 'wrap',
                  display:'inline', 
                  [theme.breakpoints.down('md')]: {
                      display: 'none'
                  },}}>
                  <Box
                      component="img"
                      sx={{
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      float: 'left',
                      }}
                      src="logo.png"
                  />
                  <nav style={{float: 'right', paddingTop: '60px'}}>
                      <Link
                      variant="button"
                      color="text.primary"
                      href="#reservation"
                      sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                      >
                      ご予約
                      </Link>
                      <Link
                      variant="button"
                      color="text.primary"
                      href="#menu"
                      sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                      >
                      メニュー
                      </Link>
                      <Link
                      variant="button"
                      color="text.primary"
                      href="#calender"
                      sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                      >
                      営業
                      </Link>
                      <Link
                      variant="button"
                      color="text.primary"
                      href="#map"
                      sx={{ my: 1, mx: 1.5, fontSize: '2em' }}
                      >
                      地図
                      </Link>
                  </nav>
          </Toolbar>
          {/* スマホ用ヘッダー */}
          <Toolbar sx={{ 
          flexWrap: 'wrap',
          display:'inline', 
          [theme.breakpoints.up('md')]: {
              display: 'none'
          },}}>
          <Box
              component="img"
              sx={{
              paddingTop: '10px',
              paddingBottom: '5px',
              }}
              src="logo.png"
          />
          <nav style={{paddingTop: '10px'}}>
              <Link
              variant="button"
              color="text.primary"
              href="#reservation"
              sx={{ my: 1, mx: 2, fontSize: '2em' }}
              >
              ご予約
              </Link>
              <Link
              variant="button"
              color="text.primary"
              href="#menu"
              sx={{ my: 1, mx: 2, fontSize: '2em' }}
              >
              メニュー
              </Link>
          </nav>
          <nav style={{paddingTop: '5px'}}>
              <Link
              variant="button"
              color="text.primary"
              href="#calender"
              sx={{ my: 1, ml: -2, mr:0, fontSize: '2em' }}
              >
              営業時間
              </Link>
              <Link
              variant="button"
              color="text.primary"
              href="#map"
              sx={{ my: 1, ml: 5, mr:1, fontSize: '2em' }}
              >
              地図
              </Link>
          </nav>
          </Toolbar>
        </div>
      </ScrollWrapper>
      {/* PC用メイン画像 */}
      <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
            },
            marginTop: '20px',
        }}>
          板橋・大山の鰻処とらじろうの公式HPです。<br/>毎朝、活きた新鮮な鰻を店内で調理しています。<br/>お持ち帰りも出来ます。
      </Typography>
      <Box
        component="img"
        sx={{
          height: '40%',
          width: '50%',
          marginTop: '20px',
          // maxHeight: { xs: 1000, md: 167 },
          // maxWidth: { xs: 2000, md: 250 },
          [theme.breakpoints.down('md')]: {
            display: 'none'
            },
        }}
        src="top.jpg"
      />
      {/* スマホ用メイン画像 */}
      <Box
        component="img"
        sx={{
          height: '80%',
          width: '100%',
          // maxHeight: { xs: 1000, md: 167 },
          // maxWidth: { xs: 2000, md: 250 },
          [theme.breakpoints.up('md')]: {
            display: 'none'
            },
        }}
        src="top.jpg"
      />
      {/* 予約 */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }} id="reservation">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          ご予約
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          ご予約はお電話にて承ります
        </Typography>
        <Typography variant="h3" align="center" color="text.secondary" component="p">
          <Link href="tel:03-3554-5677" underline="none" color={"crimson"}>
            {'03-3554-5677'}
          </Link>
        </Typography>
      </Container>
      
      {/* お知らせ(ない場合は非表示) */}
      <span/>
      <Info infoData={infoData}></Info>
      
      {/* お持ち帰りメニュー */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }}>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          お持ち帰り
        </Typography>
        <ImageList
          sx={{ width: isMediaUpMd ?1000 : 360, height: isMediaUpMd ? 600 : 400, marginLeft: isMediaUpMd ? `-200px` : `auto` }}
          cols={isMediaUpMd ?3 : 1}
          className='image-list'
          >
          {foodTakeoutData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248`}
                width="248"
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.price? <span style={{fontSize: '1.25em'}}>{item.price}円</span>: ''}
                position="below"
              />
              <span>{item.info}</span>
              {item.sub == null ?"": item.sub.map(i => <span>{i.title} : {i.price}円</span>)}
            </ImageListItem>
          ))}
        </ImageList>
      </Container>

      {/* メインメニュー */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }} id="menu">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          店内メニュー
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" marginTop={'40px'}>
          価格は税込となります
        </Typography>
        <ImageList
          sx={{ width: isMediaUpMd ?1000 : 360, height: isMediaUpMd ? 600 : 450, marginLeft: isMediaUpMd ? `-200px` : `auto` }}
          cols={isMediaUpMd ?3 : 1}

          className='image-list'
          >
          {foodData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248`}
                width="248"
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.price? <span style={{fontSize: '1.25em'}}>{item.price}円</span>: ''}
                position="below"
              />
              <span>{item.info}</span>
              {item.sub == null ?"": item.sub.map(i => <span>{i.title} : {i.price}円</span>)}
            </ImageListItem>
          ))}
        </ImageList>
        <Typography variant="h5" align="center" color="text.secondary" component="p" marginTop={'40px'}>
          その他、ドリンクやおつまみ・ご飯もの・季節物なども各種取り揃えております
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" marginTop={'20px'}>
          鰻の白焼きや直焼きも可能です
        </Typography>
      </Container>

      {/* 営業時間 */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }} id='calender'>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          
        >
          営業時間
        </Typography>

        <Typography variant="h3" align="center" color="text.secondary" component="p" sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            },
          }}>
          ランチ　：11:30～14:00（LO 13:30）
        </Typography>
        <Typography variant="h3" align="center" color="text.secondary" component="p"sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            },
          }}>
          ディナー：17:00～21:00（LO 20:30）
        </Typography>
        <Typography variant="h3" align="center" color="text.secondary" component="p" sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            },
          }}>
          11:30～14:00
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            },
          }}>
          （ラストオーダー 13:30）
        </Typography>
        <Typography variant="h3" align="center" color="text.secondary" component="p" sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            },
          }}>
          17:00～21:00
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            },
          }}>
          （ラストオーダー 20:30）
        </Typography>
        <div className='now'><Month theme={theme} now={now} holidayData={nowMonthHolidayData}></Month></div>
        <div className='next'><Month theme={theme} now={nextMonth} holidayData={nextMonthHolidayData}></Month></div>
      </Container>

      {/* 地図 */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }} id='map'>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          地図
        </Typography>
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            },
          }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12952.270138318914!2d139.6971103!3d35.7491448!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601892b613e7dd7b%3A0xb1263a1360f11b01!2z6bC75Yem44Go44KJ44GY44KN44GG!5e0!3m2!1sja!2sjp!4v1681146875568!5m2!1sja!2sjp" width="600" height="450" style={{border:0}} loading="lazy"></iframe>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            },
          }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12952.270138318914!2d139.6971103!3d35.7491448!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601892b613e7dd7b%3A0xb1263a1360f11b01!2z6bC75Yem44Go44KJ44GY44KN44GG!5e0!3m2!1sja!2sjp!4v1681146875568!5m2!1sja!2sjp" width="380" height="300" style={{border:0}} loading="lazy"></iframe>
        </Box>
      </Container>

      {/* お支払方法 */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 5 }}>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          お支払方法
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" marginTop={'20px'} id="calender">
          現金・または以下のお支払い方法が可能です
        </Typography>
        <Box
          component="img"
          sx={{
            height: '100%',
            width: '100%',
          }}
          src="jp-blog-cardbrand.avif"
        />
        <Box
          component="img"
          sx={{
            height: '10%',
            width: '10%',
          }}
          src="itabashi_pay_icon.png"
        />
        <Box
          component="img"
          sx={{
            height: '41%',
            width: '41%',
          }}
          src="itabashi_pay.png"
        />
        <Box
          component="img"
          sx={{
            height: '50%',
            width: '50%',
            marginTop: '10px',
          }}
          src="ken2025-3.jpg"
        />
      </Container>
    </div>
  );
}

export default App;
